import React from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as historyStyles from "./history.module.scss"

const History = ({ pageContext, location }) => {
  const pageTitle = "History"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container-alt inner-y-padding">
        <h2>History</h2>
        <p>
          Founded in 1932 Rocky Brands, Inc. designs, develops, manufactures and
          markets premium quality Outdoor, Work, Western, Duty and Military
          footwear as well as Outdoor and Work apparel and accessories. The
          Rocky Brands family of brands includes owned brands Rocky, Georgia
          Boot, Durango, Lehigh, The Original Muck Boot Company, XTRATUF,
          Ranger, and the licensed brand Michelin Footwear.
        </p>
        <p>
          We proudly live our heritage as a hard-working generational company
          that makes the tough, comfortable products that our customers need at
          an incredible value. We are our customer. And that is what makes us
          great and sets us apart. We love what we do, and are proud of the
          products we make. We invite our customers, consumers, and partners to
          be part of the Rocky Family and see what makes us great.
        </p>
        <div className="inner-y-padding">
          <h2>Timeline</h2>
          <ul className={historyStyles.list}>
            <li>
              <strong>1932:</strong> The Company is founded as the William
              Brooks Shoe Co. in Nelsonville, Ohio.
            </li>
            <li>
              <strong>1979:</strong> The "Rocky" brand is born.
            </li>
            <li>
              <strong>1993:</strong> The company goes public with an IPO.
              (NASDAQ: RCKY)
            </li>
            <li>
              <strong>2005:</strong> Rocky acquires EJ Footwear. Brands acquired
              include Georgia Boot&reg;, Durango&reg;, and Lehigh Safety
              Shoes&reg;.
            </li>
            <li>
              <strong>2006:</strong> Rocky Shoes and Boots officially changes
              it's name to Rocky Brands, Inc., reflecting its multi-brand,
              head-to-toe product offering.
            </li>
            <li>
              <strong>2017:</strong> Jason Brooks, Mike's youngest child and
              great-grandson of founder Mike Brooks, is appointed as CEO.
            </li>
            <li>
              <strong>2021:</strong> Rocky Brands Inc. purchased the Honeywell
              Footwear Group, adding The Original Muck Boot Company, XtraTuf,
              Neos, and Ranger to it's portfolio
            </li>
          </ul>
          <div className={historyStyles.currentEmployees}>
            <img
              src={
                require("../../assets/images/about-us/history/RockyEmployees_1933.jpg")
                  .default
              }
              alt="Rocky Brands Distribution Center Employees in 2018"
            />
            <img
              src={
                require("../../assets/images/about-us/history/2024-corporate-team.jpg")
                  .default
              }
              alt="Rocky Brands Corporate Employees in 2024"
            />
            <img
              src={
                require("../../assets/images/about-us/history/2024-distribution-team.jpg")
                  .default
              }
              alt="Rocky Brands Distribution Center Employees in 2024"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default History
